import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const Plans = () => {
    return (
        <Container className='w-100 overflow-hidden' style={ { marginTop: "8rem", marginBottom: "8rem" } }>
            <h2 className="fw-bold overflow-hidden">CHOOSE THE PLAN THAT SUITS YOU THE BEST</h2>
            <Row className="g-5 my-3">
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
                    <Card style={ { width: '100%', backgroundColor: '#E5E4E2', borderRadius: "50px" } } className="h-100 overflow-hidden">
                        <Card.Body>
                            <Card.Title className="fs-3 fw-bold mb-4 mt-5" style={ { color: "#1D048E" } }>STARTER</Card.Title>
                            <hr className="mb-5" style={ { height: '5px', width: '25%', margin: 'auto' } } />
                            <Card.Text className="mb-5">
                                <div className="w-100" style={ { height: '22rem' } }>
                                    <p>Non-Plagiarised</p>
                                    <p>Optimised</p>
                                    <p>One-time Proofread</p>
                                    <p>Negotiable Deadline</p>
                                    <p>Excellent Support</p>
                                </div>
                            </Card.Text>
                            <div className="mb-5">
                                <Link to={ { pathname: "//www.facebook.com/WriteWow-110440751504746" } } target="_blank" rel="noreferrer"><Button className="btn border py-2 px-4 text-light fw-bold rounded-pill" style={ { backgroundColor: "#1D048E" } }>GO FOR IT<FontAwesomeIcon className="ms-2" icon={ faArrowAltCircleRight } /></Button></Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
                    <Card style={ { width: '100%', backgroundColor: '#FFF5EE', borderRadius: "50px" } } className="h-100 overflow-hidden">
                        <Card.Body>
                            <Card.Title className="fs-3 fw-bold mb-4 mt-5" style={ { color: "#1D048E" } }>BASIC</Card.Title>
                            <hr className="mb-5" style={ { height: '5px', width: '25%', margin: 'auto' } } />
                            <Card.Text className="mb-5">
                                <div className="w-100" style={ { height: '22rem' } }>
                                    <p>Non-Plagiarised</p>
                                    <p>Optimised</p>
                                    <p>Multiple-Time Proofread</p>
                                    <p>Urgent Delivery</p>
                                    <p>Premium Images/Infographic</p>
                                    <p>On-Demand Support</p>
                                </div>
                            </Card.Text>
                            <div className="mb-5">
                                <Link to={ { pathname: "//www.facebook.com/WriteWow-110440751504746" } } target="_blank" rel="noreferrer"><Button className="btn border py-2 px-4 text-light fw-bold rounded-pill" style={ { backgroundColor: "#1D048E" } }>GO FOR IT<FontAwesomeIcon className="ms-2" icon={ faArrowAltCircleRight } /></Button></Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
                    <Card style={ { width: '100%', backgroundColor: '#EEEDF7', borderRadius: "50px" } } className="h-100 overflow-hidden">
                        <Card.Body>
                            <Card.Title className="fs-3 fw-bold mb-4 mt-5" style={ { color: "#1D048E" } }>STANDARD</Card.Title>
                            <hr className="mb-5" style={ { height: '5px', width: '25%', margin: 'auto' } } />
                            <Card.Text className="mb-5">
                                <div className="w-100" style={ { height: '22rem' } }>
                                    <p>Non-Plagiarised</p>
                                    <p>Copy Check Pass</p>
                                    <p>Phrase Optimisation</p>
                                    <p>Multiple-Time Proofread</p>
                                    <p>Urgent Delivery</p>
                                    <p>Premium Images/Infographic</p>
                                    <p>On-Demand Support</p>
                                </div>
                            </Card.Text>
                            <div className="mb-5">
                                <Link to={ { pathname: "//www.facebook.com/WriteWow-110440751504746" } } target="_blank" rel="noreferrer"><Button className="btn border py-2 px-4 text-light fw-bold rounded-pill" style={ { backgroundColor: "#1D048E" } }>GO FOR IT<FontAwesomeIcon className="ms-2" icon={ faArrowAltCircleRight } /></Button></Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
                    <Card style={ { width: '100%', backgroundColor: '#FFFFF0', borderRadius: "50px" } } className="h-100 overflow-hidden">
                        <Card.Body>
                            <Card.Title className="fs-3 fw-bold mb-4 mt-5" style={ { color: "#1D048E" } }>PRO</Card.Title>
                            <hr className="mb-5" style={ { height: '5px', width: '25%', margin: 'auto' } } />
                            <Card.Text className="mb-5">
                                <div className="w-100" style={ { height: '22rem' } }>
                                    <p>Non-Plagiarised</p>
                                    <p>Copy Check Pass</p>
                                    <p>Phrase Optimisation</p>
                                    <p>SurferSeo Optimized</p>
                                    <p>Multiple-Time Proofread</p>
                                    <p>Urgent Delivery</p>
                                    <p>Premium Images/Infographic</p>
                                    <p>On-Demand Support</p>
                                    <p>Unlimited Revisions</p>
                                </div>
                            </Card.Text>
                            <div className="mb-5">
                                <Link to={ { pathname: "//www.facebook.com/WriteWow-110440751504746" } } target="_blank" rel="noreferrer"><Button className="btn border py-2 px-4 text-light fw-bold rounded-pill" style={ { backgroundColor: "#1D048E" } }>GO FOR IT<FontAwesomeIcon className="ms-2" icon={ faArrowAltCircleRight } /></Button></Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Plans;