import React from 'react';
import errorPage from '../../images/404.png';

const PageNotFound = () => {
    return (
        <div>
            <img style={ { maxHeight: '100vh' } } src={ errorPage } alt="errorPageImage" />
        </div>
    );
};

export default PageNotFound;