import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Pages/Shared/Header';
import Footer from './Pages/Shared/Footer';
import Home from './Pages/Home/Home';
import PageNotFound from './Pages/Shared/PageNotFound';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import initializeFirebaseAuthentication from './Firebase/firebase.init';

initializeFirebaseAuthentication();

function App () {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/home" element={ <Home /> } />
          <Route path="/about" element={ <About /> } />
          <Route path="/contact" element={ <Contact /> } />
          <Route path="*" element={ <PageNotFound /> } />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;