import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import ScrollToTop from 'react-scroll-to-top';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
    const [ success, setSuccess ] = useState( false );
    const [ successMessage, setSuccessMessage ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ verified, setVerified ] = useState( false );

    const sendEmail = ( e ) => {
        e.preventDefault();
        emailjs.sendForm( 'service_8qweqka', 'template_7dcxb0c', e.target, '4nwD-QcwPp-62AztO' )
            .then( ( result ) => {
                setSuccess( true );
                setSuccessMessage( "Your message sent sent successfully. We'll get in touch soon." );
            }, ( error ) => {
                setSuccess( false );
                setError( error.text );
            } );
        e.target.reset();
    };

    const onChange = ( value ) => {
        setVerified( true );
    }

    return (
        <div className="w-100 overflow-hidden">
            <div className='w-100 overflow-hidden h-100 p-5 text-light' style={ { backgroundColor: "#1D048E" } }>
                <h1>Contact Writewow</h1>
            </div>
            <Container className='my-5 text-center overflow-hidden'>
                <h2 className="my-5 text-dark fs-1">Thanks for taking the time to reach out</h2>
                <h2 className="my-5 text-dark fs-1">Let's Talk Content!</h2>
                {
                    success ? <h4 className="text-success">{ successMessage }</h4> : <h4 className="text-danger">{ error }</h4>
                }
                <div className='my-5 w-75 mx-auto'>
                    <Form onSubmit={ sendEmail } className="form w-100 mx-auto overflow-hidden">
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Control type="text" placeholder="Name" name='name' required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPhone">
                            <Form.Control type="text" placeholder="Phone" name='phone' required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicJobTitle">
                            <Form.Control type="text" placeholder="Job Title" name='job' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCompany">
                            <Form.Control type="text" placeholder="Company" name='company' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Business Email" name='email' required />
                            <Form.Text className="text-light">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicIndustry">
                            <Form.Select aria-label="service" placeholder="Select Industry" name='industry' required>
                                <option>Select Industry</option>
                                <option value="Accounting">Accounting</option>
                                <option value="Addiction treatment">Addiction treatment</option>
                                <option value="Aging">Aging</option>
                                <option value="Appliances">Appliances</option>
                                <option value="Architecture">Architecture</option>
                                <option value="Automobiles">Automobiles</option>
                                <option value="B2B">B2B</option>
                                <option value="Beauty">Beauty</option>
                                <option value="Business">Business</option>
                                <option value="CBD">CBD</option>
                                <option value="Cannabis">Cannabis</option>
                                <option value="Construction">Construction</option>
                                <option value="Cryptocurrency">Cryptocurrency</option>
                                <option value="Dental">Dental</option>
                                <option value="E-commerce">E-commerce</option>
                                <option value="Education">Education</option>
                                <option value="Engineering">Engineering</option>
                                <option value="Entertainment">Entertainment</option>
                                <option value="Environment">Environment</option>
                                <option value="Fashion">Fashion</option>
                                <option value="Finance">Finance</option>
                                <option value="Fitness">Fitness</option>
                                <option value="Food &amp; recipes">Food &amp; recipes</option>
                                <option value="Gaming">Gaming</option>
                                <option value="Healthcare &amp; medical">Healthcare &amp; medical</option>
                                <option value="Hospitality">Hospitality</option>
                                <option value="Human resources">Human resources</option>
                                <option value="Insurance">Insurance</option>
                                <option value="Interior design">Interior design</option>
                                <option value="Landscaping">Landscaping</option>
                                <option value="Legal">Legal</option>
                                <option value="Marketing &amp; advertising">Marketing &amp; advertising</option>
                                <option value="Non-profits &amp; charities">Non-profits &amp; charities</option>
                                <option value="Nutrition">Nutrition</option>
                                <option value="Pest control">Pest control</option>
                                <option value="Pets">Pets</option><option value="Philosophy">Philosophy</option>
                                <option value="Photography">Photography</option>
                                <option value="Physiotherapy">Physiotherapy</option>
                                <option value="Psychology">Psychology</option>
                                <option value="Real estate">Real estate</option>
                                <option value="Retail">Retail</option>
                                <option value="SEO">SEO</option>
                                <option value="Science">Science</option>
                                <option value="Security">Security</option>
                                <option value="Sexual health">Sexual health</option>
                                <option value="Software">Software</option><option value="Sports">Sports</option>
                                <option value="Transportation">Transportation</option>
                                <option value="Travel">Travel</option>
                                <option value="Web design &amp; UX">Web design &amp; UX</option>
                                <option value="Women’s health">Women’s health</option>
                                <option value="*Other">*Other</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPackage">
                            <Form.Select aria-label="service" placeholder="Select Package" name='package' required>
                                <option>Select Package</option>
                                <option value="Starter">Starter</option>
                                <option value="Basic">Basic</option>
                                <option value="Standard">Standard</option>
                                <option value="Pro">Pro</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlContent">
                            <Form.Label className='text-dark'>Please Describe Your Content Need</Form.Label>
                            <Form.Control as="textarea" rows={ 5 } name='content' required />
                        </Form.Group>
                        <div className='d-flex justify-content-center'>
                            <ReCAPTCHA
                                sitekey="6LcexbIiAAAAAGHLlxES3Z3K4j5XDjaTIqdndOV7"
                                onChange={ onChange }
                            />
                        </div>
                        <Button variant="danger" type="submit" className='px-4 py-2 mt-4 mb-5' style={ { backgroundColor: "#1D048E" } } disabled={ !verified }>
                            <h5 className='fw-bold'>Submit</h5>
                        </Button>
                    </Form>
                </div>
                <hr className='my-5' />
                <Row className='g-5'>
                    <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                        <Card className="h-100" style={ { color: "#1D048E" } }>
                            <Card.Header><h2 className='fw-bold'>Email</h2></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <a href="mailto:career@writewow.org" className='text-decoration-none' style={ { color: "#1D048E" } }><h4>career@writewow.org</h4></a>
                                    <a href="mailto:hr.writewow@gmail.com" className='text-decoration-none' style={ { color: "#1D048E" } }><h4>hr.writewow@gmail.com</h4></a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                        <Card className="h-100" style={ { color: "#1D048E" } }>
                            <Card.Header><h2 className='fw-bold'>Address</h2></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <h4>Address will be updated soon. Please stay with us.</h4>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                        <Card className="h-100" style={ { color: "#1D048E" } }>
                            <Card.Header><h2 className='fw-bold'>Telephone</h2></Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <a className="text-decoration-none text-dark fw-bold" href="tel: +8801568983167"><h4 className='fw-bold' style={ { color: "#1D048E" } }>+88 015 6898 3167</h4></a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr className='my-5' />
                <ScrollToTop smooth />
            </Container >
        </div>
    );
};

export default Contact;