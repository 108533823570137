import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import banner from '../../images/banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const Banner = () => {
    return (
        <Container className="text-center rounded w-100 overflow-hidden">
            <Row>
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 }>
                    <div className="text-start pt-4" style={ { marginTop: "5rem", marginLeft: '2rem' } }>
                        <h3 className="mb-4 fw-bold">CONTENT THAT RANKS</h3>
                        <h3 className="mb-5 fw-bold">WORDS THAT TELL, SELL, AND INFLUENCE</h3>
                        <Link to={ { pathname: "//www.facebook.com/WriteWow-110440751504746" } } target="_blank" rel="noreferrer"><Button className="btn border mb-5 py-3 px-4 text-light fw-bold" style={ { backgroundColor: "#1D048E" } }>LET'S TALK<FontAwesomeIcon className="ms-2" icon={ faArrowAltCircleRight } /></Button></Link>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 }>
                    <img src={ banner } alt="bannerImage" className="img-fluid w-75 overflow-hidden" />
                </Col>
            </Row>
        </Container>
    );
};

export default Banner;