import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import infoArticle from '../../images/services/info.png';
import productReviews from '../../images/services/product.png';
import websiteContent from '../../images/services/website.png';
import emailCopy from '../../images/services/email.png';
import marketing from '../../images/services/marketing.png';
import education from '../../images/services/education.png';
import scriptwriting from '../../images/services/scriptwriting.png';
import storyboarding from '../../images/services/storyboarding.png';
import socialmedia from '../../images/services/socialmedia.png';

const Services = () => {
    return (
        <Container className="my-5 w-100 overflow-hidden">
            <h2 className="fw-bold text-dark overflow-hidden">OUR SERVICES</h2>
            <Row className="my-5">
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3" src={ infoArticle } alt="info" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Info Articles</h6>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3 ms-4" src={ productReviews } alt="product" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Product Reviews</h6>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-4" src={ websiteContent } alt="websiteContent" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Website Content</h6>
                    </div>
                </Col>
            </Row>
            <Row className="g-5 my-5">
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3" src={ emailCopy } alt="email" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Email Copy</h6>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3 ms-3" src={ marketing } alt="product" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Marketing Content</h6>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-4 ms-3" src={ education } alt="websiteContent" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Educational Content</h6>
                    </div>
                </Col>
            </Row>
            <Row className="g-5 my-5">
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3" src={ scriptwriting } alt="info" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Scriptwriting</h6>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3" src={ storyboarding } alt="product" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Storyboarding</h6>
                    </div>
                </Col>
                <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                    <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid me-3 ms-4" src={ socialmedia } alt="websiteContent" style={ { width: "100px" } } />
                        <h6 className="fw-bold">Social Media Content</h6>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Services;