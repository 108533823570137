import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

const About = () => {
    return (
        <div className='w-100 overflow-hidden'>
            <div className='w-100 overflow-hidden h-100 p-5 text-light' style={ { backgroundColor: "#1D048E" } }>
                <h1>About Writewow</h1>
            </div>
            <Container className="text-center text-dark" style={ { marginTop: "5rem" } }>
                <div className='text-center text-dark'>
                    <h2>If the content is the king,</h2>
                    <h2>Writewow is the foot soldier.</h2>
                    <h2>Hire us to win your battles,</h2>
                    <h2>And rank yourself higher and higher.</h2>
                </div>
                <div className='my-5 text-dark'>
                    <h4>We strive to make content creation faster and easier for businesses</h4>
                </div>
                <div className='my-5 text-dark'>
                    <h2 className='my-5 pt-5 fw-bold'>Core Values</h2>
                    <Row className="g-5 my-5">
                        <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                            <Card className="h-100">
                                <Card.Header><h4 className='fw-bold'>Trust</h4></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Without trust, we have nothing. Collaboration fails, and relationships decay. Trust is at the heart of what we stand for.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                            <Card className="h-100">
                                <Card.Header><h4 className='fw-bold'>Respect</h4></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Respect builds safety, trust, and wellbeing. Treat others as you would want to be treated.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                            <Card className="h-100">
                                <Card.Header><h4 className='fw-bold'>Quality</h4></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        "Quality is never an accident." We pride ourselves on delivering exceptional service, from the initial email to the final deliverable.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="g-5 my-5">
                        <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                            <Card className="h-100">
                                <Card.Header><h4 className='fw-bold'>Diversity</h4></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Everyone is different. We thrive on the diversity of our employees' and clients' strengths and different ways of thinking to challenge our own.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                            <Card className="h-100">
                                <Card.Header><h4 className='fw-bold'>Efficiency</h4></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Time is the most valuable asset. Ensuring our processes and systems are fast without compromising quality is crucial.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }>
                            <Card className="h-100">
                                <Card.Header><h4 className='fw-bold'>Relationships</h4></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        We strive to develop long-term relationships with our employees and clients to grow together year after year.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div style={ { marginTop: "10rem", marginBottom: "10rem" } }>
                    <h1 className='text-dark fw-bold fs-1 mt-5 mb-3'>Are you ready to bring your content to life?</h1>
                    <h5 className='text-dark fw-bold'>We’d love to hear about your content goals.</h5>
                    <Button variant='primary' className='px-3 py-2 my-3' style={ { backgroundColor: "#1D048E" } }><NavLink className="text-decoration-none fw-bold text-light" to="/contact"><h5 className='fw-bold'>LET'S TALK</h5></NavLink></Button>
                </div>
            </Container >
            <ScrollToTop smooth />
        </div>
    );
};

export default About;