import React from 'react';
import { Container } from 'react-bootstrap';
import ScrollToTop from 'react-scroll-to-top';
import Banner from './Banner';
import Plans from './Plans';
import Services from './Services';

const Home = () => {
    return (
        <Container className='w-100 overflow-hidden'>
            <Banner></Banner>
            <Services></Services>
            <Plans></Plans>
            <ScrollToTop smooth />
        </Container>
    );
};

export default Home;