import React from 'react';
import logo from '../../images/writewow-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';


const Footer = () => {
    return (
        <footer className="text-center pt-3 pb-5 w-100 overflow-hidden" style={ { backgroundColor: "#F9F6EE" } }>
            <img style={ { width: '300px' } } src={ logo } alt="logoImage" />
            <p className="fw-bold"><span><FontAwesomeIcon icon={ faFacebook } className="me-3" style={ { color: "#1972eb" } } /><Link className="text-decoration-none" style={ { color: "#1972eb" } } to={ { pathname: "//www.facebook.com/WriteWow-110440751504746" } } target="_blank" rel="noreferrer">Facebook</Link></span></p>
            <Button className="btn border py-2 px-3 text-light fw-bold mb-3" style={ { backgroundColor: "#1D048E" } }><FontAwesomeIcon className="text-light" icon={ faPhoneVolume } /> <a className="text-decoration-none text-light fw-bold" href="tel: +8801568983167">: +88 015 6898 3167</a></Button>
            <p className="fw-bold"><span><FontAwesomeIcon icon={ faEnvelopeOpen } className="me-3" />career@writewow.org</span></p>
            <p className="fw-bold"><span><FontAwesomeIcon icon={ faEnvelopeOpen } className="me-3" />hr.writewow@gmail.com</span></p>
            <p className="text-secondary text-center fs-6"><small>&copy; WriteWow { new Date().getFullYear() }</small></p>
        </footer >
    );
};

export default Footer;