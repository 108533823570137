import React from 'react';
import logo from '../../images/writewow-logo.png';
import { Button, Col, Nav, Navbar, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowAltCircleRight, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    return (
        <header className="header w-100 overflow-hidden" style={ { backgroundColor: "#f5ece4" } }>
            <Navbar collapseOnSelect expand="lg" className="nav navbar container" variant="light">
                {/* Brand Logo */ }
                <Navbar.Brand>
                    <NavLink to="/">
                        <img className='img-fluid w-100' src={ logo } alt="logoImage" />
                    </NavLink>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse id="responsive-navbar-nav">
                    {/* menu bar and primary route button */ }
                    <Nav className="me-auto">
                        <NavLink className="me-5 text-decoration-none fw-bold text-dark" to="/home"><h4 className='fw-bold'><FontAwesomeIcon icon={ faHome } /></h4></NavLink>
                        <NavLink className="me-5 text-decoration-none fw-bold text-dark" to="/about"><h4 className='fw-bold'>ABOUT US</h4></NavLink>
                        <NavLink className="me-5 text-decoration-none fw-bold text-dark" to="/contact"><h4 className='fw-bold'>CONTACT US</h4></NavLink>
                    </Nav>

                    {/* login and other menu section with conditional rendering*/ }
                    <Nav>
                        <div className='overflow-hidden w-100'>
                            <Button className="btn border py-2 px-3 text-light fw-bold" style={ { backgroundColor: "#1D048E" } }><h5 className='fw-bold'><FontAwesomeIcon className="text-light" icon={ faPhoneVolume } /> <a className="text-decoration-none text-light fw-bold" href="tel: +8801568983167">: +88 015 6898 3167</a></h5></Button>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header >
    );
};

export default Header;